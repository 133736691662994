import { parseUnits } from "ethers/lib/utils";

export default function () {
    const o = {
        // maxFeePerGas: parseUnits("100", "gwei"),
        // maxPriorityFeePerGas: parseUnits("200", "gwei"),
        gasPrice: parseUnits("1101", "gwei")
    };
    // console.log("here", o);
    return o;
}